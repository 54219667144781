import React, { memo, useCallback, useMemo } from 'react'
import { History } from 'history'
import { Dropdown, Text } from '@r1/ui-kit'
import { CustomIcon, getDataTestId } from '@r1/wireframe-primary'
import { redirect } from './utils'

type Props = {
  browserHistory: History
  pagePrefixUrl: string
}

const SITE_MAP = 'SITE_MAP'

const DEFAULT_DROPDOWN_ITEMS = [
  {
    id: SITE_MAP,
    title: <Text>Site Map</Text>,
    disabled: false,
  },
]

export const HelpMenu = memo<Props>(({ browserHistory, pagePrefixUrl }) => {
  const handleOpenSiteMap = useCallback(() => {
    redirect(`${pagePrefixUrl}/sitemap`, browserHistory)
  }, [browserHistory, pagePrefixUrl])

  const onSelectItem = useCallback(
    (id: string) => {
      switch (id) {
        case SITE_MAP: {
          handleOpenSiteMap()
          break
        }
        default:
      }
    },
    [handleOpenSiteMap],
  )

  const dropdownItems = useMemo(() => DEFAULT_DROPDOWN_ITEMS, [])

  return (
    <div>
      <Dropdown.Menu
        mode="click"
        placement="bottom-end"
        size="S"
        zIndex={51}
        items={dropdownItems}
        data-test-id={getDataTestId('header', 'help-drop-down-menu')}
        onSelectItem={onSelectItem}
      >
        <CustomIcon type="dots/horizontal" dataTestId={getDataTestId('header', 'help-menu-btn')} />
      </Dropdown.Menu>
    </div>
  )
})
